import React, { useEffect, useState } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';

import { withConfig, Theme } from 'config/';
import { formatString, timeAgo } from 'utilities/';
import { ReactComponent as ArrowIcon } from 'assets/rightArrow.svg';
import { useLanguage } from 'languages/languageContext';
import { ReactComponent as KreiseIcon } from '../../Drawer/assets/kreise.svg';
import { getConfig } from 'config/config';
import { IonIcon } from 'components/UI/IonIcon';
import { checkIfImageExists } from 'utilities/checkIfImageExists';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    width: '100%',
    // flex: 1,
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 70,
  },
  heading: {
    flexGrow: 1,
  },
  name: {
    fontSize: 17,
    fontFamily: 'Poppins',
    marginRight: 10,
    textAlign: 'left',
    fontWeight: 600,
  },
  time: {
    fontSize: 15,
    fontFamily: 'Roboto',
  },
  descriptionWrapper: {
    textAlign: 'left',
  },
  description: {
    fontSize: 15,
    fontFamily: 'Roboto',
  },
  nameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '95%',
  },
  buttonWrapper: {
    display: 'flex',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
  },
  img: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  '@media(max-width: 1024px)': {
    container: {
      // marginBottom: 15
    },
    descriptionWrapper: {
      maxWidth: 380,
    },
    nameWrapper: {
      width: '100%',
      // justifyContent: 'flex-start'
    },
  },
  '@media(max-width: 650px)': {
    container: {
      marginBottom: 15,
    },
    descriptionWrapper: {
      maxWidth: 280,
    },
    name: {
      maxWidth: 170,
    },
  },
  iconWrapper: {
    width: 34,
    height: 34,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 40,
    backgroundColor: theme.ICON_BACKGROUND,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.ICON_BORDER,
    color: theme.ICON_BORDER,
    scale: 1.5,
  },
});

interface Props extends WithStyles<typeof styles> {
  kreise: any;
  toKreisePosts: (kreiseId: string) => void;
  user: any;
}

const KreiseItem: React.FC<Props> = ({ classes, kreise, toKreisePosts, user }) => {
  const { post } = useLanguage();
  let formattedContent,
    lastPostDate,
    senderName = '';

  if (kreise && kreise.last_post && kreise.last_post.sender) {
    formattedContent = formatString(90, kreise.last_post.content || 'Bild');
    lastPostDate = timeAgo(kreise.last_post.time);
    const { sender } = kreise.last_post;
    if (sender._id) {
      senderName = `${sender.name} ${sender.lastname}: `;
    }
  }

  const Icon = ({
    privacyStatus,
    is_subscribed,
  }: {
    privacyStatus: 'private' | 'request' | 'public';
    is_subscribed: boolean;
  }) => {
    const shoudUseLockIcon = privacyStatus === 'private' || (privacyStatus === 'request' && is_subscribed);
    const shoudUseQuestionMark = privacyStatus === 'request' && !is_subscribed;

    const image = kreise?.photo?.thumb || kreise?.photo?.url || kreise?.photo;
    const [imageExists, setImageExists] = useState(false);

    useEffect(() => {
      checkIfImageExists(image, (exists) => setImageExists(exists));
    }, []);

    return (
      <div className={classes.iconWrapper}>
        {imageExists ? <img src={image} className={classes.img} /> : <KreiseIcon />}
        {(shoudUseLockIcon || shoudUseQuestionMark) && (
          <div
            style={{
              width: 14,
              height: 14,
              borderRadius: 14,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              right: 0,
              bottom: -3,
            }}
          >
            {shoudUseLockIcon && (
              <IonIcon
                name="lock-closed"
                color={theme.ICON_BORDER}
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                }}
                size={12}
              />
            )}
            {shoudUseQuestionMark && (
              <IonIcon
                name="help-circle"
                color={theme.ICON_BORDER}
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                }}
                size={12}
              />
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <ButtonBase className={classes.container} onClick={() => toKreisePosts(kreise)}>
        <Icon privacyStatus={kreise.privacyStatus} is_subscribed={kreise.is_subscribed} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: `calc(100% - 70px)`,
            paddingLeft: 20,
          }}
        >
          <div className={classes.heading}>
            <div className={classes.nameWrapper}>
              <span className={classes.name} style={{ color: theme.BUTTON_PRIMARY }}>
                {kreise.name} ({kreise.sm_num})
              </span>
              <span className={classes.time} style={{ color: theme.TEXT_SECONDARY }}>
                {lastPostDate}
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <div className={classes.descriptionWrapper}>
              <span className={classes.description} style={{ color: theme.TEXT_SECONDARY, fontWeight: 500 }}>
                {senderName}
              </span>
              <span className={classes.description} style={{ color: theme.TEXT_SECONDARY }}>
                {kreise.privacyStatus === 'request' && !kreise.last_post
                  ? kreise.request?.length
                    ? 'Mitgliedschaft beantragt - Entscheidung offen.'
                    : 'Mitgliedschaft beantragen?'
                  : formattedContent}
              </span>
            </div>
          </div>

          {/* </div> */}
        </div>
      </ButtonBase>
      <div style={{ marginLeft: 92, height: 1, backgroundColor: theme.BACKGROUND_SECONDARY, marginRight: 25 }} />
    </>
  );
};

export default withStyles(styles)(withConfig(KreiseItem));
