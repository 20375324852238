import { RootState } from '../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fileType } from './attachments';
import * as api from '../../api/attachments';

export const uploadImage = createAsyncThunk(
  'media/uploadImage',
  async (
    { file, receiverId, isPrivate }: { file: any; receiverId?: string; isPrivate?: boolean },
    { dispatch, getState },
  ) => {
    const state = getState();
    //@ts-ignore
    const idx = state.media.images.length;
    dispatch(
      mediaActions.setUploadingProgress({ file: { renderUrl: URL.createObjectURL(file) }, idx, type: 'images' }),
    );

    const res = await api.uploadImage(
      file,
      receiverId,
      (progress) => dispatch(mediaActions.setUploadingProgress({ file: { progress }, idx, type: 'images' })),
      isPrivate,
    );
    return { file: res, idx };
  },
);
export const uploadDocument = createAsyncThunk(
  'media/uploadDocument',
  async (
    {
      formdata,
      receiverId,
      isPrivate,
      thumb,
    }: { formdata: FormData; receiverId?: string; isPrivate?: boolean; thumb?: Blob },
    { dispatch, getState },
  ) => {
    try {
      const state = getState();
      //@ts-ignore
      const idx = state.media.documents.length;

      const file = thumb ? { renderUrl: URL.createObjectURL(thumb) } : {};

      dispatch(mediaActions.setUploadingProgress({ file, idx, type: 'documents' }));

      const res = await api.uploadDocument(
        formdata,
        receiverId,
        (progress) => dispatch(mediaActions.setUploadingProgress({ file: { progress }, idx, type: 'documents' })),
        isPrivate,
      );
      return { file: res, idx };
    } catch (error) {
      console.log('error ~ uploadDocument ~ createThunk', error);
    }
  },
);
export const uploadVideo = createAsyncThunk(
  'media/uploadVideo',
  async (
    { file, receiverId, isPrivate }: { file: any; receiverId?: string; isPrivate?: boolean },
    { dispatch, getState },
  ) => {
    const state = getState();
    //@ts-ignore
    const idx = state.media.videos.length;
    dispatch(
      mediaActions.setUploadingProgress({ file: { renderUrl: URL.createObjectURL(file) }, idx, type: 'videos' }),
    );

    const res = await api.uploadVideo(
      file,
      (progress) => {
        dispatch(mediaActions.setUploadingProgress({ file: { progress }, idx, type: 'videos' }));
      },
      receiverId,
      isPrivate,
    );

    return { file: res, idx };
  },
);

const initialState = {
  images: [] as fileType[],
  documents: [] as fileType[],
  videos: [] as fileType[],
  loading: false,
  loadingCounter: 0,
};

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(uploadImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      const { idx } = action.payload;
      state.loading = false;
      // @ts-ignore
      state.images[idx] = { ...state.images[idx], ...action.payload.file, progress: null };
    });
    builder.addCase(uploadImage.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(uploadDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      const { idx } = action.payload;
      state.loading = false;
      // @ts-ignore
      state.documents[idx] = { ...state.documents[idx], ...action.payload.file, progress: null };
    });
    builder.addCase(uploadDocument.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(uploadVideo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadVideo.fulfilled, (state, action) => {
      const { idx } = action.payload;
      state.loading = false;
      // @ts-ignore
      state.videos[idx] = { ...state.videos[idx], ...action.payload.file, progress: null };
    });
    builder.addCase(uploadVideo.rejected, (state) => {
      state.loading = false;
    });
  },
  reducers: {
    deleteAttachment: (state, action) => {
      const { type, id } = action.payload;
      //@ts-ignore
      state[type] = state[type].filter((_: any, idx: number) => idx !== id);
    },
    deleteAllAttachments: (state) => {
      state.documents = [];
      state.images = [];
      state.videos = [];
      state.loading = false;
      state.loadingCounter = 0;
    },
    setUploadingProgress: (state, action) => {
      const { type, file, idx } = action.payload;
      state[type][idx] = { ...state[type][idx], ...file };
    },
    setEditingAttachments: (
      state,
      action: PayloadAction<{ images: fileType[]; documents: fileType[]; videos: fileType[] }>,
    ) => {
      state.images = action.payload.images;
      state.documents = action.payload.documents;
      state.videos = action.payload.videos;
    },
  },
});

export const selectMediaState = (state: RootState) => state.media;

export const mediaActions = mediaSlice.actions;

export const media = mediaSlice.reducer;

export const trimMedia = (props) => {
  const attachments = {
    images: [] as fileType[],
    documents: [] as fileType[],
    videos: [] as fileType[],
  };

  for (const key in props)
    if (props?.[key]?.length) {
      // deleting unnecesary keys from object, must be used with spreading to not 'mutate' redux state
      props[key].map(({ renderUrl, progress, duration, ...file }) => {
        attachments[key].push(file);
      });
    }
  return attachments;
};
