import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../../api/search';

export const getGlobalSearch = createAsyncThunk(
  'globalSearch/getGlobalSearch',
  async ({
    query,
    page,
    limit,
    abortController,
  }: {
    query: string;
    page?: string;
    limit?: string;
    abortController?: AbortController;
  }) => await api.globalSearch(query, page, limit, abortController),
);

export const getGlobalSearchByName = createAsyncThunk(
  'globalSearch/getGlobalSearchByName',
  async ({
    query,
    page,
    limit,
    keys,
  }: {
    query: string;
    page: number;
    limit: number;
    keys: keyof globalSearchResponseType;
  }) => await api.globalSearchByName(query, page, limit, keys),
);

const initialState = {
  preservedQuery: '',
  results: {} as globalSearchResponseType,
  loading: false,
  error: '',
  collapsed: {} as TCollapsedItems,
};

export const globalSearch = createSlice({
  name: 'globalSearch',
  initialState: initialState,
  reducers: {
    clearResults: (state) => {
      state.loading = false;
      state.results = {} as globalSearchResponseType;
      state.collapsed = {} as TCollapsedItems;
    },
    setCollapsed: (state, action) => {
      state.collapsed[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGlobalSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.results = action.payload;
      state.preservedQuery = action.meta.arg.query;
    });
    builder.addCase(getGlobalSearch.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getGlobalSearchByName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGlobalSearchByName.fulfilled, (state, action) => {
      state.loading = false;
      state.results[action.meta.arg.keys].page = action.payload[action.meta.arg.keys].page;
      //@ts-ignore
      state.results[action.meta.arg.keys].data = [
        ...state.results[action.meta.arg.keys].data,
        ...action.payload[action.meta.arg.keys].data,
      ];
    });
    builder.addCase(getGlobalSearchByName.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const globalSearchSlice = globalSearch.reducer;
export const { clearResults, setCollapsed } = globalSearch.actions;

type TCollapsedItems = {
  users: false;
  studiengang: false;
  hochschule: false;
  events: false;
  posts: false;
  documents: false;
  interessen: false;
  postcode: false;
  kreise: false;
  charge: false;
  city: false;
  corps: false;
  country: false;
  berufCompany: false;
  berufIndustry: false;
  berufTitel: false;
  folders: false;
};

export type globalSearchResponseType = {
  users: UserResults;
  corps: CorpResults;
  events: EventResults;
  kreise: ChatResults;
  posts: PostResults;
  documents: TDocuments;
  folders: TFolders;
} & TUserFields;

export type TUserFields = {
  city: TGlobalSearchField;
  country: TGlobalSearchField;
  postcode: TGlobalSearchField;
  charge: TGlobalSearchField;
  interessen: TGlobalSearchField;
  berufTitel: TGlobalSearchField;
  berufIndustry: TGlobalSearchField;
  berufCompany: TGlobalSearchField;
  studiengang: TGlobalSearchField;
  hochschule: TGlobalSearchField;
};

export type TGlobalSearchField = {
  data: {
    name: string;
    count: number;
  }[];
  total: number;
  page: number;
};

interface TDocuments {
  data: documentsType[];
  total: number;
  page: number;
}

interface documentsType {
  _id: string;
  uploader: string;
  filename: string;
  mimetype: string;
  path: string;
  kreise: string | null;
  parent: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  deletedAt: string;
  content: string;
  thumb?: string;
}

interface PostResults {
  data: postsType[];
  total: number;
  page: number;
}

interface postsType {
  _id: string;
  post_type: string;
  content: string;
  kreise: chatTypes;
  sender: {
    name: string;
    lastname: string;
  };
}

interface ChatResults {
  data: chatTypes[];
  total: number;
  page: number;
}

interface chatTypes {
  _id: string;
  name: string;
}

interface EventResults {
  data: eventType[];
  total: number;
  page: number;
}

interface eventType {
  _id: string;
  title: string;
  description: string;
  location: string;
}

interface CorpResults {
  data: corpType[];
  total: number;
  page: number;
}

interface corpType {
  _id: string;
  name: string;
  members: number;
}

interface UserResults {
  data: userType[];
  total: number;
  page: number;
}

interface userType {
  _id: string;
  name: string;
  lastname: string;
  photo: string;
}

interface TFolders {
  data: TFolderItem[];
  total: number;
  page: number;
}

interface TFolderItem {
  _id: string;
  title: string;
}
