import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getValues } from 'api/common';
import { RootState } from '..';
import * as defaultData from '../../utilities/data';
import { parseBoolean } from 'config/setConfig';
import { TEventStatus } from 'scenes/Calendar/CalendarScreen';
import { TVisibilityOption } from 'scenes/CreateEvent/CreateEvent';

export type TWidgetState = {
  isWidget: boolean;
  inviter: string;
};

interface IRestriction {
  overall: string;
  profile: string;
  groupchat: string;
  groupchatWilkar: string;
  comments: string;
  commentsWilkar: string;
  likes: string;
  post: string;
}

export const getAppValues = createAsyncThunk('common/getAppValues', async () => {
  const res = await getValues();
  return res;
});

const initialState = {
  loading: false,
  maximal_membership_count: 9999,
  sign_up_description: '',
  show_all_mentors: false,
  data: defaultData,
  notifications_config: {} as { own_post_notify: boolean | undefined },
  disable_code_registration: false,
  disable_guest_function: true,
  widget: {} as TWidgetState,
  social_media: false,
  allow_doc_deletion: true,
  guest_naming_singular: 'Gast',
  guest_naming_plural: 'Gäste',
  member_naming_singular: 'Mitglied',
  tou_link: 'https://www.connact.app/agb',
  dpd_link: 'https://www.connact.app/datenschutz',
  accept_agb_at_passwort_reset: false,
  users_can_create_new_corps: false,
  users_can_request_new_corps: false,
  allow_bio: false,
  allow_link: false,
  allow_attachments: false,
  newsfeed_popup: false,
  event_show_website_option: false,
  event_default_visibility_option: 'all' as TVisibilityOption,
  event_default_tab: 'all' as TEventStatus,
  show_noninvited_events: true,
  event_standard_link_registration: 'App', // for now unused, delete comment when im needed
  event_standard_registration_option_link: 'app',
  event_creation_ask_registration_type: false,
  restrictions: {} as IRestriction,
  allow_mentoring_edit: false,
  support_email: 'support@connact.app',
  event_participants_limitation: 0, // if == 0, this feature is disabled
  html_preview_height: 10,
  registration_agb_checkbox: false,
  first_login_accept_agb: null,
  admin_can_delete_posts: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsWidget: (state, action: PayloadAction<TWidgetState>) => {
      state.widget = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppValues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppValues.fulfilled, (state, action: any) => {
      state.loading = false;
      const maximal_membership_count = !Number(action.payload?.maximal_membership_count)
        ? 9999
        : action.payload.maximal_membership_count;
      state.maximal_membership_count = maximal_membership_count;
      state.sign_up_description = action.payload?.sign_up_description;

      if (action.payload?.charge) state.data.corpsFunction = action.payload.charge;
      if (action.payload?.status) state.data.corpsStatuses = action.payload.status;

      state.show_all_mentors = action.payload.show_all_mentors ?? false;

      if (action.payload?.notificationsConfig?.ownPostNotify)
        state.notifications_config.own_post_notify =
          action.payload?.notificationsConfig?.ownPostNotify === '1' ? true : false;

      state.disable_code_registration = action.payload?.disable_code_registration ?? false;

      state.disable_guest_function = action.payload?.disable_guest_function ?? true;

      state.social_media = !!action.payload?.social_media;
      state.allow_doc_deletion = !action.payload?.allow_doc_deletion ? action.payload?.allow_doc_deletion : true;

      if (action.payload?.guest_naming_singular) state.guest_naming_singular = action.payload.guest_naming_singular;
      if (action.payload?.guest_naming_plural) state.guest_naming_plural = action.payload.guest_naming_plural;
      if (action.payload?.member_naming_singular) state.member_naming_singular = action.payload.member_naming_singular;

      if (action.payload?.dpd_link) state.dpd_link = action.payload.dpd_link;
      if (action.payload?.tou_link) state.tou_link = action.payload.tou_link;

      state.accept_agb_at_passwort_reset = action.payload?.accept_agb_at_passwort_reset ?? false;

      state.users_can_create_new_corps = action.payload?.users_can_create_new_corps ?? false;
      state.users_can_request_new_corps = action.payload?.users_can_request_new_corps ?? false;

      state.allow_bio = action.payload?.allow_bio ?? false;
      state.allow_link = action.payload?.allow_link ?? false;
      state.allow_attachments = action.payload?.allow_attachments ?? false;
      state.newsfeed_popup = action.payload?.newsfeed_popup ?? false;

      state.show_noninvited_events = action.payload?.show_noninvited_events ?? true;

      state.event_show_website_option = action.payload?.event_show_website_option
        ? action.payload?.event_show_website_option
        : false;

      state.event_default_visibility_option = action.payload?.event_default_visibility_option
        ? action.payload?.event_default_visibility_option.toLowerCase()
        : 'all';

      state.event_default_tab = action.payload?.event_default_tab
        ? action.payload?.event_default_tab.toLowerCase()
        : 'all';

      state.event_creation_ask_registration_type = action.payload?.event_creation_ask_registration_type ?? false;

      state.registration_agb_checkbox = action.payload?.registration_agb_checkbox ?? false;

      state.first_login_accept_agb = action.payload?.first_login_accept_agb ?? false;

      state.allow_mentoring_edit = action.payload?.allow_mentoring_edit ?? false;
      state.support_email = action.payload?.support_email ?? 'support@connact.app';

      state.event_standard_registration_option_link = action.payload?.event_standard_registration_option_link
        ? action.payload?.event_standard_registration_option_link
        : 'app';

      state.event_standard_link_registration = action.payload?.event_standard_link_registration
        ? action.payload?.event_standard_link_registration
        : '';

      state.restrictions = action.payload?.restrictions ? action.payload?.restrictions : state.restrictions;

      state.event_participants_limitation = +action.payload?.event_participants_limitation ?? 0;

      state.html_preview_height = +action.payload?.html_preview_height || 10;

      state.admin_can_delete_posts = action.payload?.admin_can_delete_posts ?? false;
    });
    builder.addCase(getAppValues.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectCommonState = (state: RootState) => state.common;

export const commonActions = commonSlice.actions;

export const common = commonSlice.reducer;
