import { Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import ImageView from '../../../components/Shared/ImageLightBox/ImageLightBox';
import { fileType } from '../../../store/reducers/attachments';

type Props = {
  images: fileType[];
  imageIndex: number;
  openImage: (i: number) => void;
  hideImage: () => void;
  visible: boolean;
};
export const ImagesPreview: FC<Props> = ({ images, imageIndex = 0, openImage, hideImage, visible }) => {
  const [previews, setPreviews] = useState([] as fileType[][]);
  const imageHeight = 200;

  useEffect(() => {
    let newPreviews = [];
    const chunkSize = 3;
    for (let i = 0; i < images.length; i += chunkSize) {
      const chunk = images.slice(i, i + chunkSize);
      newPreviews.push(chunk);
    }
    setPreviews(newPreviews);
  }, []);
  const rowCounter = previews.length;
  const RenderRow = (_, idx: number) => {
    return (
      <Box
        flexDirection="row"
        display="flex"
        key={idx}
        style={{
          height: rowCounter * imageHeight,
          overflow: 'hidden',
        }}
      >
        {previews[idx]?.map((image, imageIdx) => {
          const id = imageIdx + idx * 3;
          return (
            <Box
              display="flex"
              style={{
                ...styles.image,
                width: `${100 / previews[idx].length}%`,
              }}
              onClick={() => openImage(id)}
              key={'preview' + imageIdx}
            >
              <img
                src={image.url}
                style={{
                  height: imageHeight,
                  objectFit: 'cover',
                }}
              />
            </Box>
          );
        })}
      </Box>
    );
  };
  return (
    <>
      <Box>{previews.map(RenderRow)}</Box>
      <ImageView images={images} showGallery={visible} onClose={hideImage} incomingIndex={imageIndex} />
    </>
  );
};

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 300,
    'flex-direction': 'row',
  },
  image: {},
};
