import React, { FC, ReactNode, useState } from 'react';
import { useAppDispatch } from '../../store';
import { useLanguage } from '../../languages/languageContext';

import { uploadDocument, uploadVideo, uploadImage } from '../../store/reducers/mediaSlice';
import FilesLoader from 'components/Shared/FillesLoader';
import { showResponse } from 'store/actions/response';
import PDFToJPGConverter from 'utilities/PDFToJPGConverter';
import { fileType } from 'store/reducers/attachments';

export const UploadMedia: FC<{
  receiver?: any;
  slideToBottom?: boolean;
  viewType?: 'default' | 'button' | 'dropzone';
  addPictureDisabled?: boolean;
  addPDFDisabled?: boolean;
  addVideoDisabled?: boolean;
  allFormats?: boolean;
  isPrivate?: boolean;
  additionalBtn?: ReactNode[];
  multiple?: boolean;
  text?: string;
}> = ({
  receiver,
  slideToBottom = false,
  viewType = 'default',
  addPictureDisabled = false,
  addPDFDisabled = false,
  addVideoDisabled = false,
  allFormats = false,
  isPrivate = false,
  additionalBtn,
  multiple = true,
  text,
}) => {
  const dispatch = useAppDispatch();
  const { responseHandler } = useLanguage();
  const [showLoader, setShowLoader] = useState(false);
  const [pdfs, setPdfs] = useState([]);

  const onDropFiles = (files: any) => {
    setShowLoader(false);
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        if (file.size > 2048 * 1e6) {
          dispatch(showResponse({ message: responseHandler.maximalVideoSize }));
          return;
        }
        if (file.type.includes('video')) {
          dispatch(uploadVideo({ file, receiverId: receiver?._id, isPrivate }));
          return;
        }
        if (file.type.includes('image')) {
          dispatch(uploadImage({ file, receiverId: receiver?._id, isPrivate }));
          return;
        }
        if (file.type === 'application/pdf') {
          const formdata = new FormData();
          formdata.append('document', file);
          const payload: Partial<fileType> = {
            size: file.size,
            mimetype: file.type,
            fileName: file.name,
            renderUrl: URL.createObjectURL(file),
            formdata,
          };
          setPdfs((c) => [...c, payload]);
          return;
        }

        const formdata = new FormData();
        formdata.append('document', file);
        dispatch(uploadDocument({ formdata, receiverId: receiver?._id, isPrivate }));
      };
    });
  };

  return (
    <>
      <FilesLoader
        onDrop={onDropFiles}
        showLoader={showLoader}
        loaderHandler={() => setShowLoader(!showLoader)}
        slideToBottom={slideToBottom}
        viewType={viewType}
        addPictureDisabled={addPictureDisabled}
        addPDFDisabled={addPDFDisabled}
        addVideoDisabled={addVideoDisabled}
        allFormats={allFormats}
        additionalBtn={additionalBtn}
        multiple={multiple}
        text={text}
      />
      {pdfs.map((pdf) => (
        <PDFToJPGConverter pdf={pdf} setPdfs={setPdfs} isPrivate={isPrivate} receiverId={receiver?._id} />
      ))}
    </>
  );
};
