import React, { CSSProperties, FC } from 'react';
import linkDecorator from './linkDecorator';
import Linkify from 'react-linkify';
import { isValidUrl } from 'utilities';
interface IContentProps {
  content: string;
  style?: CSSProperties;
  className?: string;
}

/*
    This component is created only to check if the content has emojis.
    If it has it, it converts the content into HTML, otherwise it simply renders
*/

const Content: FC<IContentProps> = ({ content = '', style, className }) => (
  <Linkify componentDecorator={linkDecorator}>
    {content.includes('#') && !isValidUrl(content) ? (
      <div className={className} style={style} dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      <div className={className} style={style}>
        {content}
      </div>
    )}
  </Linkify>
);

export default Content;
