import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import PDFIcon from '../../assets/pdf-file.png';
import { IonIcon } from 'components/UI/IonIcon';

export const AttachmentImage: FC<{ file: any; type: 'image' | 'document' | 'file'; viewType?: 'row' | 'column' }> = ({
  file,
  type,
  viewType = 'column',
}) => {
  return type === 'image' ? (
    <Box
      style={{
        ...styles.imageWrapper,
        position: 'relative',
        display: 'flex',
        width: viewType === 'column' ? 140 : 40,
        height: viewType === 'column' ? 80 : 40,
      }}
    >
      <img
        alt="Preview"
        src={file.renderUrl || file.url}
        key={file.renderUrl || file.url}
        style={{
          ...styles.image,
          objectFit: 'cover',
        }}
      />
    </Box>
  ) : (
    <Box style={viewType === 'column' ? { ...styles.pdf, position: 'relative', display: 'flex' } : {}}>
      {file.renderUrl || file.thumb ? (
        <img
          alt="PDF"
          src={file.renderUrl || file.thumb || PDFIcon}
          key={file.url}
          width={40}
          style={{ alignSelf: 'center' }}
        />
      ) : (
        <IonIcon name="document-outline" size={40} />
      )}
    </Box>
  );
};

const styles = {
  imageWrapper: {
    backgroundColor: '#ccc',
    width: 140,
    height: 80,
    marginBottom: 15,
    marginRight: 15,
    zIndex: 0,
    borderRadius: 5,
  },
  pdf: {
    width: 140,
    height: 40,
    marginBottom: 15,
    marginRight: 15,
    zIndex: 0,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingTop: 10,
  },
  image: {
    flex: 1,
    width: undefined,
    height: undefined,
    zIndex: 0,
    borderRadius: 5,
  },
};
