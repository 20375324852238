import * as Yup from 'yup';
import { isValidUrl, patterns, validateInput } from './validation';
import { translationsType } from 'languages/languageContext';

type invalidInputs = {
  invalidInputs: translationsType['invalidInputs'];
};

export const signInSchema = (signIn) =>
  Yup.object().shape({
    email: Yup.string().email(signIn.loginValidation).required(signIn.loginValidation),
    password: Yup.string().required('Bitte Passwort eingeben'),
  });

export const signUpSchema = ({ invalidInputs, isSoroptimist, withCode, registration_agb_checkbox }) =>
  Yup.object().shape({
    name: Yup.string().required(invalidInputs.fieldRequired),
    lastname: Yup.string().required(invalidInputs.fieldRequired),
    email: Yup.string().email(invalidInputs.emailInvalid).required(invalidInputs.emailInvalid),
    emailRepeat: Yup.string()
      .email(invalidInputs.emailInvalid)
      .required(invalidInputs.fieldRequired)
      .test('email-match', invalidInputs.emailsNotMatching, function (value) {
        return value === this.parent.email;
      }),
    mobilfunknummer: Yup.string().required(invalidInputs.fieldRequired),
    password: Yup.string()
      .matches(patterns.password, invalidInputs.passwordChars)
      .required(invalidInputs.fieldRequired),
    postcode: Yup.string().required(invalidInputs.fieldRequired),
    wohnort: Yup.string().required(invalidInputs.fieldRequired),
    country: Yup.string().min(1, invalidInputs.fieldRequired).required(invalidInputs.fieldRequired),

    beruf: isSoroptimist ? Yup.string().required(invalidInputs.fieldRequired) : undefined,
    street: isSoroptimist ? Yup.string().required(invalidInputs.fieldRequired) : undefined,
    birthday: isSoroptimist ? Yup.date().required(invalidInputs.fieldRequired) : undefined,

    invitationCode: withCode ? Yup.string().required(invalidInputs.fieldRequired) : undefined,
    corp: !withCode ? Yup.array().min(1, invalidInputs.corp).required(invalidInputs.fieldRequired) : undefined,

    privacyPolicy: registration_agb_checkbox ? Yup.boolean().oneOf([true], invalidInputs.fieldRequired) : undefined,
  });

export const validateEmail = ({ invalidInputs }) => Yup.string().email(invalidInputs.emailInvalid).required();

export const eventValidation = ({ invalidInputs, eventLabels }) =>
  Yup.object().shape({
    title: Yup.string().required(invalidInputs.fieldRequired),
    description: Yup.string(),
    location: Yup.string().required(invalidInputs.fieldRequired),
    organizerMsg: Yup.string().required(invalidInputs.fieldRequired),
    startDate: Yup.date(),
    endDate: Yup.date().min(Yup.ref('startDate'), eventLabels.startAfterEndError),
    invitedKreises: Yup.array().required().min(1, 'Bitte Zielgruppe wählen'),
    registrationType: Yup.string(),
    registrationLink: Yup.string().when('registrationType', 
		{
			is:  'event',
			then: (schema) => schema.required(invalidInputs.fieldRequired)
		})
    // .test('email/link', 'must be valid link or email', function (value) {
    //   const isApp = this.parent.registrationType === 'app';
    //   if (isApp) return true;
    //   let isValid = false;
    //   isValid = validateInput(value, patterns.email);
    //   if (!isValid) isValid = isValidUrl(value);
    //   return isValid;
    // }),
  });

export const editProfileValidations = ({ invalidInputs }: invalidInputs) =>
  Yup.object().shape({
    title: Yup.string(),
    name: Yup.string().required(invalidInputs.fieldRequired),
    lastname: Yup.string().required(invalidInputs.fieldRequired),
    email: Yup.string().email(invalidInputs.emailInvalid).required(invalidInputs.emailInvalid),
    postcode: Yup.string().required(invalidInputs.fieldRequired),
    country: Yup.string(),
    wohnort: Yup.string().required(invalidInputs.fieldRequired),
    birthday: Yup.string(),
    mobilfunknummer: Yup.string(),
    street: Yup.string(),
    partner: Yup.string(),
    telephoneLandline: Yup.string(),
    showBirthday: Yup.string(),
    leibbursch: Yup.string(),
    leibfuechse: Yup.string(),
    bio: Yup.string(),
    attachments: Yup.object().shape({
      link: Yup.string().matches(patterns.link, 'should be link or empty'),
      image: Yup.string(),
      video: Yup.string(),
      pdf: Yup.string(),
    }),
  });
