import { getConfig } from 'config/config';

const contacts = {
  aps: [
    'Vertretungsberechtigter Vorstand:',
    '',
    'Geschäftsführender Vorstand des Aktionsbündnis Patientensicherheit e. V.',
    '',
    'Dr. Ruth Hecker, Vorsitzende',
    '',
    'Dr. Christian Deindl, stellvertretender Vorsitzender',
    '',
    'Generalsekretär: Joachim Maurice Mielert',
    '',
    '',
    '',
    'Registergericht',
    '',
    'Amtsgericht Marburg',
    '',
    'VR-2276',
    '',
    'Umsatzsteuer-Identifikations­nummer',
    '',
    'UmSt.-ID: DE 270931117',
    '',
    'Inhaltlich verantwortlich:',
    '',
    'Aktionsbündnis Patientensicherheit e. V.',
    '',
    'https://www.aps-ev.de',
  ],
};

const { config } = getConfig();
export const additionalContacts = contacts[config.APP_NAME] || [];
