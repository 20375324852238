import React from 'react';

import { InfoWindow, Marker } from '@react-google-maps/api';
import { LocationOn } from '@material-ui/icons';
import { getConfig } from 'config/config';
import { Box } from '@material-ui/core';

const { theme } = getConfig();

class MapMarker extends React.Component {
  render() {
    //@ts-ignore
    const { clusterer, markerData, image, isSelected, onClick, isInfoOpen, userIsGuest } = this.props;
    const href = markerData.type === 'user' ? `/profile-details/${markerData.id}` : `/suche/corpsid/${markerData.id}`;
    return (
      <Marker
        key={markerData.id}
        clusterer={clusterer}
        position={{
          lat: markerData.lat,
          lng: markerData.lng,
        }}
        onClick={() => onClick(true, markerData.filter)}
        // visible={false}
        // icon={{
        //   url: '',
        //   size: new window.google.maps.Size(0, 0),
        //   origin: new window.google.maps.Point(0, 0),
        //   anchor: new window.google.maps.Point(0, 0),
        //   scaledSize: new window.google.maps.Size(0, 0)
        // }}
      >
        {isInfoOpen && isSelected && (
          <InfoWindow
            key={markerData.id + 'info'}
            options={{
              disableAutoPan: true,
            }}
          >
            <div
              style={{ alignItems: 'center', display: 'flex', position: 'relative', padding: 2, borderRadius: 5 }}
              onClick={() => {
                if (typeof userIsGuest === 'function') {
                  userIsGuest();
                  return;
                }
                window.open(href, '_blank');
              }}
            >
              {markerData.type === 'user' && <img src={image} style={{ width: 48, height: 48, borderRadius: 48 }} />}
              <Box
                flexDirection={'column'}
                display="flex"
                alignItems={'center'}
                marginLeft={markerData.type === 'user' ? 2 : 0}
              >
                <span>{markerData.fullName}</span>
              </Box>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default React.memo(MapMarker);
