import { client } from './client';
import { API } from '../constants/';

// ADD VIDEO
export const uploadVideo = async (
  video: any,
  handleUploadProgress: (c: number) => void,
  receiverId: string = '',
  isPrivate: boolean = false,
) => {
  let formdata = new FormData();
  formdata.append('video', video);
  if (receiverId.length) receiverId = `/${receiverId}`;

  const res = await client.post(`/v4/upload/aws/video${receiverId}?private=${isPrivate}`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    onUploadProgress(progressEvent) {
      const uploadProgress = 100 / (progressEvent.total / progressEvent.loaded);
      handleUploadProgress(uploadProgress);
    },
  });
  return res;
};

// ADD ATTACHMENT
export const uploadDocument = async (
  formdata: FormData,
  receiverId: string = '',
  handleUploadProgress?: (c: number) => void,
  isPrivate: boolean = false,
) => {
  if (receiverId.length) receiverId = `/${receiverId}`;

  const res = await client.post(`${API}/v4/upload/aws/document${receiverId}?private=${isPrivate}`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    onUploadProgress(progressEvent) {
      const uploadProgress = 100 / (progressEvent.total / progressEvent.loaded);
      handleUploadProgress(uploadProgress);
    },
  });

  return res;
};

export const uploadImage = async (
  document: any,
  receiverId: string = '',
  handleUploadProgress?: (c: number) => void,
  isPrivate: boolean = false,
) => {
  let formdata = new FormData();
  formdata.append('image', document);
  if (receiverId.length) receiverId = `/${receiverId}`;

  const res = await client.post(`${API}/v4/upload/aws/image${receiverId}?private=${isPrivate}`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    onUploadProgress(progressEvent) {
      const uploadProgress = 100 / (progressEvent.total / progressEvent.loaded);
      handleUploadProgress(uploadProgress);
    },
  });
  return res;
};

export const getS3Prefix = async () => await client.get('/v4/aws-info');
