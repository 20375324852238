export const downloadFile = async (fileUrl: string, name: string) => {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error occurred while downloading the file:', error);
  }
};
