import { getConfig } from 'config/config';

const { config } = getConfig();

export const patterns: { [key: string]: RegExp } = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  characters: /^.{6,}$/,
  required: /^.{1,}$/,
  phone: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
  password:
    config.APP_NAME === 'atlas'
      ? /^(?=.*[A-Za-zäöüÄÖÜß])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-zäöüÄÖÜß\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{12,}$/
      : /^(?=.*[A-Za-zäöüÄÖÜß])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-zäöüÄÖÜß\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,}$/,
  link: /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)$/,
};

export const validateInput = (value: any, pattern: any): boolean => {
  if (pattern.test(value)) return true;
  return false;
};

export const isValidUrl = (url: string): boolean => {
  const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})+)(?:\/\S*)?$/;
  return urlRegex.test(url);
};
