import { KreiseItemType } from 'store/types/kreiseTypes';
import { API } from '../constants/';
import { client } from './client';
import { handleResponse, authHeader, handleConnectionError } from './core';
import { fileType } from 'store/reducers/attachments';

// GET KREISE BY ID
export const getKreiseById = (kreiseId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/kreisebyid/${kreiseId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET USER KREISE
export const getUserKreise = (page: number = 1, limit: number = 1, query: string = '') => {
  const userId = localStorage.getItem('userId');
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };
  const search = query.length ? `&query=${query}` : '';
  return fetch(`${API}/v3/kreise/${userId}?page=${page}&limit=${limit}${search}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const getKreiseForEvents = (userId: string): Promise<KreiseItemType[]> =>
  client.get(`${API}/v3/kreise/${userId}?event=true`);

// GET KREISE
export const getKreise = (skip, limit) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v3/kreise/${skip}/${limit}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// SEARCH KREISE
export const searchKreise = (query: string, skip: number, limit: number) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v3/kreise/${skip}/${limit}?name=${query}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// SUBSCRIBE KREISE
export const subscribeKreise = (payload: object) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(`${API}/v2/kreise/subscribe`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET KREISE SUBSCRIBERS
export const getKreiseSubscribers = (kreiseName: string, page: number, limit: number) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/kreise/subscribers/${kreiseName.replace(/\//g, '%2F')}/${page}/${limit}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const setKreiseSettings = (
  period: number | string,
  kreiseId: string,
  key: 'email' | 'push' | 'newUser' | 'statistic',
  type?: string,
) => client.patch('/v2/profile/kreiseSettings', { period, kreiseId, key, type });

export const sendKreiseRequest = (kreiseId: string) => client.post('/request/kreise', { targetId: kreiseId });

export const deleteKreiseRequest = (requestId: string) => client.delete(`/request/${requestId}`);

export const getKreiseRequests = () => client.get(`/request/kreise`);

export const createKreise = (kreise: { name: string; description?: string; members: string[] }) =>
  client.post('/v2/kreise', kreise);

export const deleteKreise = (kreiseId: string) => client.delete(`/v2/kreise/${kreiseId}`);

export const addUserToKreise = (kreiseId: string, userId: string, adminId: string) =>
  client.post(`/v2/kreise/${kreiseId}/members/${userId}?exclude=${adminId}`);

export const removeUserFromKreise = (kreiseId: string, userId: string) =>
  client.delete(`/v2/kreise/${kreiseId}/members/${userId}`);

export const addAdminToKreise = (kreiseId: string, userId: string) =>
  client.post(`/v2/kreise/${kreiseId}/admin/${userId}`);

export const removeAdminFromKreise = (kreiseId: string, userId: string) =>
  client.delete(`/v2/kreise/${kreiseId}/admin/${userId}`);

export const editKreise = (
  kreiseId: string,
  {
    name,
    description,
    suggestionInNewsfeed,
    photo,
  }: { name?: string; description?: string; suggestionInNewsfeed?: { any }[]; photo?: fileType },
) => client.put(`/v2/kreise/${kreiseId}`, { name, description, suggestionInNewsfeed, photo });
