import { RootState, store } from 'store';
import * as api from './../../api/news';
import * as adsApi from './../../api/advertisement';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export const getNewsList = createAsyncThunk(
  'news/getNewsList',
  async ({ page, limit }: { page: number; limit: number; adNumber; addAds }) => {
    return await api.getNewsList(page, limit);
  },
);
export const getPinnedPosts = createAsyncThunk(
  'news/getPinnedPosts',
  async ({ id }: { id: string; type: 'news' | 'kreiseNews' }) => {
    return await api.getPinnedPosts(id);
  },
);

export const getNewsListById = createAsyncThunk(
  'news/getNewsListById',
  async ({ kreiseId, page, limit }: { kreiseId: string; page: number; limit: number }) => {
    return await api.getNewsListById(kreiseId, page);
  },
);

export const getNewsById = createAsyncThunk('news/getNewsById', async ({ postId }: { postId: string }) => {
  return await api.getNewsById(postId);
});

export const deleteNews = createAsyncThunk('news/deleteNews', async ({ postId }: { postId: string }) => {
  return await api.deleteNews(postId);
});

export const getAllAds = createAsyncThunk('news/getAllAds', async () => {
  try {
    return await adsApi.getAllAds();
  } catch (err) {
    console.log('errrrr', err);
  }
});

export const addNews = createAsyncThunk('news/addNews', async ({ postEntity }: { postEntity: any }) => {
  return await api.addNews(postEntity);
});

export const submitEditing = createAsyncThunk(
  'news/submitEditing',
  async ({ postEntity, type }: { postEntity: any; type: 'news' | 'kreiseNews' }) => {
    const post = await api.editPost(postEntity);
    return { post, type };
  },
);

export const createSocialMediaPost = createAsyncThunk(
  'news/createSocialMediaPost',
  async ({ kreiseId, value, media }: { kreiseId: string; value: string; media: any }) => {
    return await api.createSocialMediaPost(kreiseId, value, media);
  },
);

export const getKreiseToPost = createAsyncThunk('news/getKreiseToPost', async () => {
  return await api.getKreiseToPost();
});

type TVote = {
  postId: string;
  optionId: string;
};

export const surveyAddVote = createAsyncThunk('news/surveyAddVote', async ({ postId, optionId }: TVote) =>
  api.surveyAddVote(postId, optionId),
);

export const surveyRemoveVote = createAsyncThunk('news/surveyRemoveVote', async ({ postId, optionId }: TVote) =>
  api.surveyRemoveVote(postId, optionId),
);

export const surveyGetVotes = createAsyncThunk(
  'news/surveyGetVotes',
  async ({ postId, optionId, page, limit }: TVote & { page: number; limit: number }) =>
    api.surveyGetVotes(postId, optionId, page, limit),
);

const socialMediaInitialState = {
  show: false,
  value: '',
  media: [] as { link: string; type: 'image' | 'video' }[],
  loading: false,
  editable: false,
  kreiseId: '',
  step: 0 as 0 | 1 | 2,
  originalText: '',
};

const initialState = {
  news: [],
  newsIsLoading: false,
  newsCount: 0,
  kreiseNews: [],
  kreiseNewsIsLoading: false,
  kreiseNewsCount: null as 0,
  addingNews: false,
  currentNews: null,
  adsList: [],
  adsIsLoading: false,
  editingPost: null as any,
  socialMediaPost: socialMediaInitialState,
  newsPageNumber: 1,
  kreiseNewsPageNumber: 1,
  adsNumber: 0,
  kreiseForNews: [] as { _id: string; name: string; isTicked: boolean }[],
  pinnedNewsFetched: false,
  pinnedKreiseNewsFetched: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    clearNews: (state) => {
      state.news = [];
      state.newsCount = 0;
    },
    clearKreiseNews: (state) => {
      state.kreiseNews = [];
      state.kreiseNewsCount = null;
    },
    clearAds: (state) => {
      state.adsList = [];
    },
    startEditing: (state, action: any) => {
      state.editingPost = action.payload;
    },
    cancelEditing: (state) => {
      state.editingPost = null;
    },
    setSocialMediaPost: (state, action: PayloadAction<Partial<typeof socialMediaInitialState>>) => {
      state.socialMediaPost = { ...state.socialMediaPost, ...action.payload };
    },
    setSocialMediaPostInitialState: (state) => {
      state.socialMediaPost = socialMediaInitialState;
    },
    setNewsPageNumber: (state, action: PayloadAction<number>) => {
      state.newsPageNumber = action.payload;
    },
    setKreiseNewsPageNumber: (state, action: PayloadAction<number>) => {
      state.kreiseNewsPageNumber = action.payload;
    },
    modifyPost: (state, action: PayloadAction<{ type: 'kreiseNews' | 'news'; post: any; moveToTop?: boolean }>) => {
      const idx = state[action.payload.type].findIndex((el) => el?._id === action.payload.post?._id);
      state[action.payload.type][idx] = action.payload.post;

      if (action.payload.moveToTop) state[action.payload.type].unshift(state[action.payload.type].splice(idx, 1)[0]);
    },
  },
  extraReducers: (builder) => {
    // --> getNewsList
    builder.addCase(getNewsList.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(getNewsList.fulfilled, (state, action: any) => {
      const adNum = action.meta.arg.adNumber || 0;
      const pushedNews = [...action.payload.post];
      pushedNews.splice(1, 0, state.adsList[adNum]);
      const concatedNews = action.meta.arg.addAds ? pushedNews : action.payload.post;

      state.newsIsLoading = false;
      state.news = state.news.concat(concatedNews);
      state.newsCount = action.payload.total;
    });
    builder.addCase(getNewsList.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  getNewsList <--
    // --> getPinnedPosts
    builder.addCase(getPinnedPosts.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(getPinnedPosts.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      const concatedNews = action.payload.post;

      state.newsIsLoading = false;
      state[type] = state[type].concat(concatedNews);
      if (type === 'news') state.pinnedNewsFetched = true;
      if (type === 'kreiseNews') state.pinnedKreiseNewsFetched = true;
    });
    builder.addCase(getPinnedPosts.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  getPinnedPosts <--

    // --> getNewListsById
    builder.addCase(getNewsListById.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(getNewsListById.fulfilled, (state, action: any) => {
      state.newsIsLoading = false;
      state.kreiseNews = state.kreiseNews.concat(action.payload.Data.post);
      state.kreiseNewsCount = action.payload.Data.total;
    });
    builder.addCase(getNewsListById.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  getNewListsById <--

    // --> getNewsById
    builder.addCase(getNewsById.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(getNewsById.fulfilled, (state, action: any) => {
      state.newsIsLoading = false;
      state.currentNews = action.payload.Data;
    });
    builder.addCase(getNewsById.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  getNewsById <--

    // --> deleteNews
    builder.addCase(deleteNews.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(deleteNews.fulfilled, (state, action: any) => {
      const newsArr = state.news.filter((news: any) => news?._id !== action.meta.arg.postId);
      state.news = newsArr;
      state.newsIsLoading = false;
    });
    builder.addCase(deleteNews.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  deleteNews <--

    // --> getAllAds
    builder.addCase(getAllAds.pending, (state) => {
      state.adsIsLoading = true;
    });
    builder.addCase(getAllAds.fulfilled, (state, action: any) => {
      const sortedAds = action.payload?.Data?.sort((a, b) => 0.5 - Math.random());

      state.adsIsLoading = false;
      state.adsList = sortedAds;
    });
    builder.addCase(getAllAds.rejected, (state) => {
      state.adsIsLoading = false;
    });
    //  getAllAds <--

    // --> addNews
    builder.addCase(addNews.pending, (state) => {
      state.addingNews = true;
    });
    builder.addCase(addNews.fulfilled, (state, action: any) => {
      state.addingNews = false;

      if (action.meta.arg?.postEntity?.post_type === 'news') {
        state.news = [...action.payload.Data, ...state.news];
      }

      if (typeof action.meta.arg?.postEntity?.post_type === 'object') {
        state.news = [...action.payload.Data, ...state.news];
        state.kreiseNews = [...action.payload.Data, ...state.kreiseNews];
      }

      const { content, images, videos } = action.meta.arg?.postEntity;
      if (action.payload.Data.some((el) => el.socialPosting) && (!!images?.length || !!videos?.length)) {
        state.socialMediaPost = {
          value: !content.length ? 'Ein:e Nutzer:in unser App hat ein neues Bild geteilt!' : '',
          media: [
            ...images.map((img: any) => ({
              link: img.url,
              type: 'image',
            })),
            ...videos.map((vid: any) => ({
              link: vid.url,
              type: 'video',
            })),
          ],
          show: true,
          loading: false,
          editable: false,
          kreiseId: action.payload.Data.filter((el) => el.socialPosting).map((el) => el.kreise),
          step: 1,
          originalText: content,
        };
      }
    });
    builder.addCase(addNews.rejected, (state) => {
      state.addingNews = false;
    });
    //  addNews <--

    // --> submitEditing
    builder.addCase(submitEditing.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(submitEditing.fulfilled, (state, action) => {
      state.newsIsLoading = false;
      let { type } = action.payload;
      const idx = state[type].findIndex((post) => post?._id === action.payload.post._id);
      state[type][idx] = { ...state[type][idx], ...action.payload.post };
      state.editingPost = null;
    });
    builder.addCase(submitEditing.rejected, (state) => {
      state.newsIsLoading = false;
      // state.editingPost = null
    });
    //  submitEditing <--

    // --> createSocialMediaPost
    builder.addCase(createSocialMediaPost.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(createSocialMediaPost.fulfilled, (state) => {
      state.newsIsLoading = false;
      state.socialMediaPost = socialMediaInitialState;
    });
    builder.addCase(createSocialMediaPost.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  createSocialMediaPost <--

    // --> addVote
    builder.addCase(surveyAddVote.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(surveyAddVote.fulfilled, (state, action: any) => {
      state.newsIsLoading = false;

      const idx = state.news.findIndex((post) => post?._id === action.payload._id);
      if (idx !== -1) state.news[idx].survey = action.payload.survey;

      if (state.currentNews?._id === action.payload._id) {
        state.currentNews.survey = action.payload.survey;
      }

      const kreiseIdx = state.kreiseNews.findIndex((post) => post?._id === action.payload._id);
      if (kreiseIdx !== -1) state.kreiseNews[kreiseIdx].survey = action.payload.survey;
    });
    builder.addCase(surveyAddVote.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  addVote <--

    // --> removeVote
    builder.addCase(surveyRemoveVote.pending, (state) => {
      state.newsIsLoading = true;
    });
    builder.addCase(surveyRemoveVote.fulfilled, (state, action: any) => {
      state.newsIsLoading = false;
      const idx = state.news.findIndex((post) => post?._id === action.payload._id);
      if (idx !== -1) state.news[idx].survey = action.payload.survey;

      if (state.currentNews?._id === action.payload._id) {
        state.currentNews.survey = action.payload.survey;
      }

      const kreiseIdx = state.kreiseNews.findIndex((post) => post?._id === action.payload._id);
      if (kreiseIdx !== -1) state.kreiseNews[kreiseIdx].survey = action.payload.survey;
    });
    builder.addCase(surveyRemoveVote.rejected, (state) => {
      state.newsIsLoading = false;
    });
    //  removeVote <--

    // --> getKreiseToPost
    // builder.addCase(getKreiseToPost.pending, (state) => {}),
    // builder.addCase(getKreiseToPost.fulfilled, (state, action: PayloadAction<{data:string[]}>) => {
    // @ts-ignore
    builder.addCase(
      getKreiseToPost.fulfilled,
      (state, action: PayloadAction<{ data: { _id: string; name: string; isTicked: boolean }[] }>) => {
        state.kreiseForNews = action.payload.data;
      },
    );
    // builder.addCase(getKreiseToPost.rejected, (state) => {}),
    //  getKreiseToPost <--
  },
});

export const news = newsSlice.reducer;
export const {
  clearNews,
  clearAds,
  startEditing,
  cancelEditing,
  setSocialMediaPost,
  setSocialMediaPostInitialState,
  setNewsPageNumber,
  clearKreiseNews,
  setKreiseNewsPageNumber,
  modifyPost,
} = newsSlice.actions;
export const selectNews = (state: RootState) => state.news;
