import { Box } from "@material-ui/core";
import DocumentsGallery from "components/Shared/DocumentsGallery";
import { VideoPlayer } from "components/Shared/VideoPlayer";
import React, { FC, useEffect, useState } from "react";
// import { View, StyleSheet, Image } from "react-native";
import Video from 'react-player';
import { getS3Link } from "utilities/setS3Prefix";
import { fileType } from "../../../store/reducers/attachments";
import { ImagesPreview } from "./ImagesPreview";

export type attachmentsType = {
  documents: fileType[]
  images: fileType[]
  videos: fileType[]
}


export const MessageAttachments: FC<{ attachments: attachmentsType }> = ({ attachments }) => {
  // attachments?.images?.forEach(async file => { if (!file.url.includes('http')) file.url = await getS3Link(file.url) })
  const [fixed, setFixed] = useState({} as attachmentsType)
  const [fullscreenImage, setFullscreenImage] = useState({ show: false, index: 0 })

  const openImage = (index: number) => setFullscreenImage({ show: true, index })
  const hideImage = () => setFullscreenImage({ show: false, index: 0 })
  useEffect(() => {
    fixLinks()
  }, [])
  const fixLinks = () => {
    const newObj = {} as attachmentsType

    for (const key in attachments)
      if (attachments?.[key]?.length) {
        newObj[key] = []

        attachments[key].map(file => {
          if (!file.url.includes('http')) {
            const extendedFile = { ...file, url: getS3Link(file.url) }
            // @ts-ignore
            newObj[key].push(extendedFile)
            return
          }
          newObj[key].push(file)
        })
      }

    setFixed(newObj)
  }
  return <Box style={styles.wrapper} flexDirection='column'>
    {fixed?.videos?.map((file, i) => <VideoPlayer videos={[file]} key={file.url + i} />)}
    {!!fixed?.images?.length && <ImagesPreview
      images={fixed.images}
      imageIndex={fullscreenImage.index}
      openImage={openImage}
      hideImage={hideImage}
      visible={fullscreenImage.show}
    />}
    {!!fixed?.documents?.length &&
      <DocumentsGallery files={fixed.documents} />
    }
  </Box>
}


const styles = {
  wrapper: {
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
  },
}
