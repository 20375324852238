import axios from 'axios';
import { store } from 'store';
import { signOutRequest } from 'store/actions/auth';
import { getConfig } from './../config/config';
import { showResponse } from 'store/actions/response';
import { getLocalStorage } from 'utilities/localStorageHandler';
import { serverActions } from 'store/reducers/server';

const { config } = getConfig();

export const client = axios.create({
  baseURL: config.API,
  headers: {
    'Content-Type': 'application/json',
    'Content-language': getLocalStorage('userLang'),
  },
  withCredentials: true,
  validateStatus: (status) => status >= 200 && status < 500,
});

client.interceptors.request.use(
  async (config) => {
    const token = getLocalStorage('token');
    if (token) {
      //@ts-ignore
      config.headers = { ...config.headers, 'x-access-token': token };
    }
    return config;
  },
  (error) => {
    console.log('error is', error);
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (res) => {
    const { data, status, statusText } = res;
    if (res.status === 401) {
      store.dispatch(signOutRequest());
      return Promise.reject({
        message: 'Error 401. Bitte loggen Sie sich erneut ein.',
      });
    }
    if (data.Boolean === false) {
      store.dispatch(showResponse({ message: data.Status || data.message }));
      return Promise.reject({ message: data.Status || data.message });
    }

    if (data.Token) {
      return data;
    }

    return data.Data || data;
  },
  (error) => {
    if (error.response.status === 502) {
      store.dispatch(showResponse({ message: 'no connection', immediately: true }, '/reconnect'));
      store.dispatch(serverActions.setIsAlive(false));
    }
    return Promise.reject(error?.response.data);
  },
);
