import { Moment } from 'moment';
import {
  CLEAR_BIRTHDAY_EVENTS,
  GET_BIRTHDAY_EVENTS_REQUEST,
  GET_BIRTHDAY_EVENTS_FAILURE,
  GET_BIRTHDAY_EVENTS_SUCCESS,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_MY_EVENTS_REQUEST,
  GET_MY_EVENTS_SUCCESS,
  GET_MY_EVENTS_FAILURE,
  GET_CORPS_EVENTS_REQUEST,
  GET_CORPS_EVENTS_SUCCESS,
  GET_CORPS_EVENTS_FAILURE,
  CLEAR_EVENTS,
  GET_EVENT_BY_ID_REQUEST,
  GET_EVENT_BY_ID_SUCCESS,
  GET_EVENT_BY_ID_FAILURE,
  CLEAR_CURRENT_EVENT,
  GET_EVENT_MEMBERS_REQUEST,
  GET_EVENT_MEMBERS_SUCCESS,
  GET_EVENT_MEMBERS_FAILURE,
  CLEAR_EVENT_MEMBERS,
  ADD_EVENT_MEMBER_REQUEST,
  ADD_EVENT_MEMBER_SUCCESS,
  ADD_EVENT_MEMBER_FAILURE,
  ADD_EVENT_GUEST_REQUEST,
  REMOVE_EVENT_MEMBER_REQUEST,
  REMOVE_EVENT_MEMBER_SUCCESS,
  REMOVE_EVENT_MEMBER_FAILURE,
  REMOVE_EVENT_GUEST_REQUEST,
  GET_AUTOCOMPLETE_EVENTS_REQUEST,
  GET_AUTOCOMPLETE_EVENTS_SUCCESS,
  GET_AUTOCOMPLETE_EVENTS_FAILURE,
  GET_CORPS_CONTACTS_REQUEST,
  GET_CORPS_CONTACTS_SUCCESS,
  GET_CORPS_CONTACTS_FAILURE,
  CLEAR_CORPS_CONTACTS,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  REMOVE_EVENT_REQUEST,
  REMOVE_EVENT_SUCCESS,
  REMOVE_EVENT_FAILURE,
  GET_EVENT_BY_MAIL_REQUEST,
} from '../../constants/';

// GET_EVENTS_SUCCESS
export const setEvents = (events: any[], name: 'events' | 'eventList') => ({
  type: GET_EVENTS_SUCCESS,
  name,
  events,
});

// GET EVENT BY ID
export const getEventByIdRequest = (eventId: string) => ({
  type: GET_EVENT_BY_ID_REQUEST,
  eventId,
});

export const getEventByIdSuccess = (event: object) => ({
  type: GET_EVENT_BY_ID_SUCCESS,
  event,
});

export const getEventByIdFailure = (error: object) => ({
  type: GET_EVENT_BY_ID_FAILURE,
  error,
});

// GET EVENT BY MAIL
export const getEventByMailRequest = (eventId: string, email: string) => ({
  type: GET_EVENT_BY_MAIL_REQUEST,
  eventId,
  email,
});

// GET EVENT MEMBERS
export const getEventMembersRequest = (eventId: string, page: number, limit: number) => ({
  type: GET_EVENT_MEMBERS_REQUEST,
  eventId,
  page,
  limit,
});

export const getEventMembersSuccess = (eventMembers: Array<any>, total: number) => ({
  type: GET_EVENT_MEMBERS_SUCCESS,
  eventMembers,
  total,
});

export const getEventMembersFailure = (error: object) => ({
  type: GET_EVENT_MEMBERS_FAILURE,
  error,
});

export const clearEventMembers = () => ({
  type: CLEAR_EVENT_MEMBERS,
});

// ADD EVENT MEMBER
export const addEventMemberRequest = (
  eventId: string,
  userId?: string,
  recConfig?: { startDate: string; recurring: boolean },
) => ({
  type: ADD_EVENT_MEMBER_REQUEST,
  eventId,
  userId,
  recConfig,
});

export const addEventMemberSuccess = () => ({
  type: ADD_EVENT_MEMBER_SUCCESS,
});

export const addEventMemberFailure = (error: object) => ({
  type: ADD_EVENT_MEMBER_FAILURE,
  error,
});

// ADD EVENT GUEST MEMBER
export const addEventGuestRequest = (
  eventId: string,
  email: string,
  guest: {
    guestEmail: string;
    guestName: string;
  },
) => ({
  type: ADD_EVENT_GUEST_REQUEST,
  eventId,
  email,
  guest,
});

// REMOVE EVENT MEMBER
export const removeEventMemberRequest = (eventId: string, userId: string) => ({
  type: REMOVE_EVENT_MEMBER_REQUEST,
  eventId,
  userId,
});

export const removeEventMemberSuccess = () => ({
  type: REMOVE_EVENT_MEMBER_SUCCESS,
});

export const removeEventMemberFailure = (error: object) => ({
  type: REMOVE_EVENT_MEMBER_FAILURE,
  error,
});

// REMOVE EVENT GUEST MEMBER
export const removeEventGuestRequest = (eventId: string, option: { email: string } | { guestId: string }) => ({
  type: REMOVE_EVENT_GUEST_REQUEST,
  eventId,
  option,
});

// GET AUTOCOMPLETE EVENTS
export const getAutocompleteEventsRequest = () => ({
  type: GET_AUTOCOMPLETE_EVENTS_REQUEST,
});

export const getAutocompleteEventsSuccess = (autocompletes: Array<object>) => ({
  type: GET_AUTOCOMPLETE_EVENTS_SUCCESS,
  autocompletes,
});

export const getAutocompleteEventsFailure = (error: object) => ({
  type: GET_AUTOCOMPLETE_EVENTS_FAILURE,
  error,
});

// GET CORPS CONTACTS
export const getCorpsContactsRequest = (corpsName: string) => ({
  type: GET_CORPS_CONTACTS_REQUEST,
  corpsName,
});

export const getCorpsContactsSuccess = (contacts: Array<object>) => ({
  type: GET_CORPS_CONTACTS_SUCCESS,
  contacts,
});

export const getCorpsContactsFailure = (error: object) => ({
  type: GET_CORPS_CONTACTS_FAILURE,
});

export const clearCorpsContacts = () => ({
  type: CLEAR_CORPS_CONTACTS,
});

// ADD EVENT
export const addEventRequest = (event: object, callback: any) => ({
  type: ADD_EVENT_REQUEST,
  event,
  callback,
});

export const addEventSuccess = (event: object) => ({
  type: ADD_EVENT_SUCCESS,
  event,
});

export const addEventFailure = (error: object) => ({
  type: ADD_EVENT_FAILURE,
  error,
});

// UPDATE EVENT
export const updateEventRequest = (event: object, callback: any) => ({
  type: UPDATE_EVENT_REQUEST,
  event,
  callback,
});

export const updateEventSuccess = (event: object) => ({
  type: UPDATE_EVENT_SUCCESS,
  event,
});

export const updateEventFailure = (error: object) => ({
  type: UPDATE_EVENT_FAILURE,
  error,
});

// REMOVE EVENT
export const removeEventRequest = (
  eventId: string,
  notify: boolean,
  payload: { date: Moment; recurringModifyType: string },
  callback: any,
) => ({
  type: REMOVE_EVENT_REQUEST,
  eventId,
  notify,
  payload,
  callback,
});

export const removeEventSuccess = ({eventId}) => ({
  type: REMOVE_EVENT_SUCCESS,
  eventId
});

export const removeEventFailure = (error: object) => ({
  type: REMOVE_EVENT_FAILURE,
  error,
});
