import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import SearchableModal from 'components/Shared/SearchableModal';
import { useLanguage } from 'languages/languageContext';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { searchRequest } from 'store/reducers/searchSlice';

type Props = {
  open: boolean;
  onClose: () => void;
  values: any[];
  onSelect: (c: any) => void;
  onDelete: (c: any) => void;
};

export const AddOrganizersModal: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { eventLabels } = useLanguage();
  const { open, onClose, values, onSelect, onDelete } = props;

  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  const { data, count, isLoading } = useAppSelector((state) => state.search.name);

  useEffect(() => {
    getMoreUsersHandler(true);
  }, [query]);

  const getMoreUsersHandler = (clear = false) => {
    if (isLoading) return;

    setPage((c) => c + 1);

    if ((page === 1 || count > data.length) && !isLoading) {
      dispatch(searchRequest({ page, filter: 'name', searchkey: query, clear }));
    }
  };

  return (
    <SearchableModal
      modalOpen={open}
      modalTitle={eventLabels.creatorLabel}
      onClick={onClose}
      setValueHandler={onClose}
      value={undefined}
    >
      <MultipleSearchableInput
        data={data}
        onSelect={onSelect}
        deleteItemHandler={onDelete}
        placeholder={eventLabels.creatorLabel}
        onChange={(e) => {
          setPage(1);
          setQuery(e.target.value);
        }}
        values={values}
        value={query}
        paginated={true}
        loadMore={getMoreUsersHandler}
        loading={isLoading}
        hasMore={data?.length < count}
      />
    </SearchableModal>
  );
};
