export const formatString = (stringLength: number, value: string) => {
  let newString = '';

  if (value && value.length > stringLength) {
    const str = value.slice(0, stringLength);
    newString = `${str}...`;
    return newString;
  }

  return value;
};

export const makeDateBold = (str: string) => {
  // Define a regular expression pattern to match the date in the format "dd.mm.yyyy hh:mm"
  const dateRgx = /\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}/g;

  if (typeof str !== 'string' || !str?.match(dateRgx)) return str;

  const formattedString = str?.replace(dateRgx, (match: string) => `<strong>${match}</strong>`);

  return <span dangerouslySetInnerHTML={{ __html: formattedString }} />;
};
