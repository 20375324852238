import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loader from '../../components/UI/Loader';
import Button from '../../components/UI/Button';
import BenachrichtigungenItem from './components/BenachrichtigungenItem';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCommonState } from 'store/reducers/common';
import { getConfig } from 'config/config';
import { showResponse } from 'store/actions/response';
import { updateUserNotificationsRequest } from 'store/actions/users';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: 420,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 20,
  },
  info: {
    fontSize: 14,
    marginTop: 70,
    marginBottom: 50,
  },
  inputsWrapper: {
    position: 'relative',
    marginBottom: 20,
  },
  optionsWrapper: {
    marginBottom: 50,
  },
  buttonWrapper: {},
  button: {
    marginTop: 30,
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
});

interface Props {}

const ProfileBenachrichtigungen: React.FC<Props> = () => {
  const { notifications_config: notificationsConfig } = useAppSelector(selectCommonState);
  const [state, setState] = React.useState({
    notifications: {
      clubMember: {
        enabled: true,
      },
      comments: {
        enabled: true,
      },
      contactRequests: {
        enabled: true,
      },
      events: {
        enabled: true,
      },
      messages: {
        enabled: true,
      },
      posts: {
        enabled: true,
      },
      ownPosts: {
        enabled: notificationsConfig?.own_post_notify || false,
      },
    },
    all: {
      enabled: true,
    },
    showModal: false,
  });

  const dispatch = useAppDispatch();
  const { user, userIsLoading } = useAppSelector((state) => state.users);
  const language = useLanguage();
  const classes = useStyles({});

  React.useEffect(() => {
    if (user && user.mailingNotifyOptions) {
      const { mailingNotifyOptions } = user;
      const allEnabled = Object.values(mailingNotifyOptions);
      const result = allEnabled.every((option: any) => option.enabled);

      setState({
        ...state,
        notifications: {
          ...mailingNotifyOptions,
          events: {
            enabled: true,
          },
          posts: {
            enabled: true,
          },
        },
        all: {
          enabled: !!result,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (user && user.mailingNotifyOptions) {
      const { mailingNotifyOptions } = user;

      const allEnabled = Object.values(mailingNotifyOptions);
      const result = allEnabled.every((option: any) => option?.enabled);

      setState({
        ...state,
        notifications: {
          ...mailingNotifyOptions,
          events: {
            enabled: true,
          },
          posts: {
            enabled: true,
          },
        },
        all: {
          enabled: !!result,
        },
      });
    }
  }, [user.mailingNotifyOptions]);

  // componentDidMount() {
  //   const { user } = props;

  //   if (user && user.mailingNotifyOptions) {
  //     const { mailingNotifyOptions } = user;
  //     const allEnabled = Object.values(mailingNotifyOptions);
  //     const result = allEnabled.every((option: any) => option.enabled);

  //     setState({
  //       notifications: {
  //         ...mailingNotifyOptions,
  //       },
  //       all: {
  //         enabled: !!result,
  //       },
  //     });
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const { user } = props;

  //   if (prevProps && user && user.mailingNotifyOptions) {
  //     const { mailingNotifyOptions } = user;

  //     if (JSON.stringify(prevProps.user.mailingNotifyOptions) !== JSON.stringify(mailingNotifyOptions)) {
  //       const allEnabled = Object.values(mailingNotifyOptions);
  //       const result = allEnabled.every((option: any) => option.enabled);

  //       setState({
  //         notifications: {
  //           ...mailingNotifyOptions,
  //         },
  //         all: {
  //           enabled: !!result,
  //         },
  //       });
  //     }
  //   }
  // }

  const notificationHandler = (optionName: keyof typeof state, value: any) => {
    if (user && user.name) {
      if (optionName === 'all') {
        setState({
          ...state,
          all: value,
          notifications: {
            clubMember: value,
            comments: value,
            contactRequests: value,
            events: value,
            messages: value,
            posts: value,
            ownPosts: value,
          },
        });
      } else {
        const allEnabled = Object.values(state.notifications);
        const result = allEnabled.every((option) => option?.enabled);

        setState({
          ...state,
          notifications: {
            ...state.notifications,
            [optionName]: value,
          },
          all: {
            enabled: !!result,
          },
        });
      }
    } else if (user && !user.name) {
      dispatch(
        showResponse({
          message: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
        }),
      );
    }
  };

  const updateUserHandler = () => {
    if (user && user.name) {
      dispatch(updateUserNotificationsRequest({ ...state.notifications }));
    } else if (user && !user.name) {
      dispatch(
        showResponse({
          message: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
        }),
      );
    }
  };

  const { profileNotifications, btn } = language;
  const titlesArray = [
    {
      optionName: 'messages',
      title: profileNotifications.messagesLabel,
    },
    {
      optionName: 'contactRequests',
      title: profileNotifications.contactRequestsLabel,
    },
    {
      optionName: 'comments',
      title: profileNotifications.commentsLabel,
    },
    {
      optionName: 'ownPosts',
      title: profileNotifications.ownPosts,
    },
    {
      optionName: 'events',
      title: profileNotifications.eventsLabel,
      onPress: () => {
        dispatch(showResponse({ message: 'Diese Einstellungen kannst du im jeweiligen Gruppenchat vornehmen.' }));
      },
      disabled: true,
    },
    {
      optionName: 'posts',
      title: profileNotifications.postsLabel,
      onPress: () => {
        dispatch(showResponse({ message: 'Diese Einstellungen kannst du im jeweiligen Gruppenchat vornehmen.' }));
      },
      disabled: true,
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.container}>
        <p className={classes.info} style={{ color: theme.TEXT_PRIMARY }}>
          {profileNotifications.notificationsInfo}
        </p>
        <div className={classes.optionsWrapper}>
          {titlesArray.map((option) => (
            <BenachrichtigungenItem
              key={option.optionName}
              title={option.title}
              optionName={option.optionName}
              notificationHandler={option?.onPress || notificationHandler}
              mailingNotifyOptionSettings={state.notifications[option.optionName]}
              disabled={option.disabled}
            />
          ))}
        </div>
        <Button label={btn.saveBtn} onClick={updateUserHandler} />
        <Loader isLoading={userIsLoading} />
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  showResponse,
  updateUserNotificationsRequest,
};

export default ProfileBenachrichtigungen;
