import { FC, useEffect, useMemo, useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import BackButton from 'components/Shared/BackButton';
import { IonIcon } from 'components/UI/IonIcon';
import Layout from 'components/UI/Layout';
import { getConfig } from 'config/config';
import { useCustomHistory } from 'hooks/useCustomHistory';
import EditIcon from '@material-ui/icons/Edit';
import { useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { useLanguage } from 'languages/languageContext';
import MembersComponent from 'components/Shared/MembersComponent';
import InfiniteScroll from 'react-infinite-scroller';
import { getEventByIdRequest, removeEventGuestRequest } from 'store/actions/events';
import { useDispatch } from 'react-redux';
import { hideResponse, showResponse } from 'store/actions/response';

import { useInfiniteQuery } from '@tanstack/react-query';
import { getEventMembers } from 'api';
import { Button } from 'components/shadcn/button';
import { cn } from 'utilities/utils';
import { exportParticipants, getEventWaitList } from 'api/events';
import { downloadFile } from 'utilities/downloadFile';
import moment from 'moment';

const { theme, config } = getConfig();

const Participants: FC = () => {
  const history = useCustomHistory();
  const user = useAppSelector((state) => state.users.user);
  const { currentEvent: reduxCurrEvent } = useAppSelector((state) => state.events);
  const { eventLabels, kreiseSettings, btn } = useLanguage();
  const dispatch = useDispatch();
  const styles = useStyles({});
  const location = useLocation();

  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'members' | 'waitlist'>('members');
  const [members, setMembers] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [membersTotal, setMembersTotal] = useState(0);
  const [waitlistTotal, setWaitlistTotal] = useState(0);

  const currentEvent = location.state?.event || reduxCurrEvent;
  const eventRegistrationType = currentEvent?.registration?.type;
  const defaultEventRegistrationType = eventRegistrationType === 'app';
  const eventId = useMemo(() => location.pathname.replace('/participants/', ''), []);
  const waitlistAvailable = !!(currentEvent?.participantsLimit && currentEvent?.participantsLimit !== 0);

  const {
    data: membersData,
    fetchNextPage: fetchMembers,
    hasNextPage: hasMoreMembers,
    isFetchingNextPage: isFetchingMoreMembers,
    refetch: refetchMembers,
    dataUpdatedAt: membersUpdatedAt,
  } = useInfiniteQuery({
    queryKey: ['members', eventId],
    queryFn: ({ pageParam }) => {
      console.log('pageParam', pageParam);
      return getEventMembers(eventId, pageParam, 10);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      const count = allPages?.reduce((acc, page) => [...acc, ...page?.data], []);
      if (count?.length >= lastPage?.total || !lastPage?.data?.length) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  const {
    data: waitlistData,
    fetchNextPage: fetchWaitlist,
    hasNextPage: hasMoreWaitlist,
    isFetchingNextPage: isFetchingMoreWaitlist,
    refetch: refetchWaitlist,
    dataUpdatedAt: waitlistUpdatedAt,
  } = useInfiniteQuery({
    queryKey: ['waitlist', eventId],
    queryFn: ({ pageParam }) => getEventWaitList(eventId, pageParam, 10),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      const count = allPages?.reduce((acc, page) => [...acc, ...page?.data], []);
      if (count?.length >= lastPage?.total || !lastPage?.data?.length) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled: waitlistAvailable,
  });

  useEffect(() => {
    if (!currentEvent) dispatch(getEventByIdRequest(eventId));
  }, []);

  useEffect(() => {
    if (!membersData?.pages?.length) return;
    setMembers((c) => [...c, ...membersData.pages[membersData.pages?.length - 1]?.data]);
    setMembersTotal(membersData?.pages?.[0]?.total);
  }, [membersUpdatedAt]);

  useEffect(() => {
    if (!waitlistData?.pages?.length) return;
    setWaitlist((c) => [...c, ...waitlistData.pages[waitlistData.pages?.length - 1]?.data]);
    setWaitlistTotal(waitlistData?.pages?.[0]?.total);
  }, [waitlistUpdatedAt]);

  const turnOnEditMode = () => {
    if (editMode === false) setEditMode(true);
    if (editMode === true) {
      setEditMode(false);
      setMembers([]);
      setWaitlist([]);
      refetchMembers();
      refetchWaitlist();
    }
  };

  const removeGuest = (guestId: string, type: 'members' | 'waitlist') => {
    dispatch(
      showResponse({
        message: `${kreiseSettings.deleteMemberDescr}`,
        title: kreiseSettings.deleteKreiseModalTitle,
        cancelBtnLabel: btn.declineReqBtn,
        options: [
          <Button
            onClick={() => {
              const listCopy = type === 'members' ? [...members] : [...waitlist];
              const updatedList = listCopy.filter((item) => item.id._id !== guestId);
              type === 'members' && setMembers(updatedList);
              type === 'waitlist' && setWaitlist(updatedList);
              dispatch(hideResponse());
              dispatch(removeEventGuestRequest(currentEvent._id, { guestId }));
              type === 'members' && setMembersTotal((c) => c - 1);
              type === 'waitlist' && setWaitlistTotal((c) => c - 1);
            }}
            className="w-[220px] mb-2 self-center"
          >
            {kreiseSettings.deleteMemberBtn}
          </Button>,
        ],
      }),
    );
  };

  const RenderList = ({
    title,
    total,
    isFetching,
    hasMore,
    data,
    loadMore,
    deleteMember,
  }: {
    isFetching: boolean;
    hasMore: boolean;
    data: any[];
    loadMore: () => void;
    title: string;
    total: string | number;
    deleteMember: (id: string) => void;
  }) => {
    return (
      <div className={'flex flex-col'} style={{ background: theme.BACKGROUND }}>
        <InfiniteScroll
          threshold={100}
          initialLoad={false}
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={true}
        >
          {data?.map((member) => (
            <div key={member?._id} className="flex flex-row items-center px-2">
              <MembersComponent registrationType={!defaultEventRegistrationType} key={member?._id} like={member} />
              {user?._id !== member?._id && editMode && (
                <ButtonBase onClick={() => deleteMember(member?.id?._id)}>
                  <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                </ButtonBase>
              )}
            </div>
          ))}
          {isFetching && <InfiniteScroll />}
        </InfiniteScroll>
      </div>
    );
  };

  console.log(currentEvent?.sender, user?._id, currentEvent?.sender === user?._id);

  return (
    <Layout>
      <BackButton
        title={`${!defaultEventRegistrationType ? 'Interessenten' : eventLabels.participantLabel}`}
        onClick={() => history.goBack()}
        additionalButton={
          (currentEvent?.sender === user?._id || currentEvent?.sender?._id === user?._id) && (
            <div className="gap-2 flex flex-row">
              <ButtonBase
                style={{
                  backgroundColor: theme.ACTIVE_INPUT,
                  height: 30,
                  width: 30,
                  borderRadius: 30,
                }}
                onClick={() =>
                  downloadFile(
                    exportParticipants(eventId),
                    `${config.APP_FULL_NAME}_${currentEvent.title}_TN-Export_${moment().format('YYYY_MM_DD')}`,
                  )
                }
              >
                <IonIcon name="cloud-download" size={20} color="#fff" />
              </ButtonBase>
              <ButtonBase
                className={styles.header}
                onClick={turnOnEditMode}
                style={{
                  color: theme.ACTIVE_INPUT,
                  height: 30,
                  width: 30,
                  borderRadius: 30,
                }}
              >
                {!editMode ? (
                  <div
                    style={{
                      color: theme.BACKGROUND_PRIMARY,
                      backgroundColor: theme.ACTIVE_INPUT,
                      width: 30,
                      height: 30,
                      borderRadius: 30,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <EditIcon color="inherit" />
                  </div>
                ) : (
                  <IonIcon
                    name={'checkmark'}
                    size={20}
                    color={theme.BACKGROUND}
                    style={{
                      backgroundColor: theme.ACTIVE_INPUT,
                      borderRadius: 40,
                      padding: 5,
                    }}
                  />
                )}
              </ButtonBase>
            </div>
          )
        }
      />
      {waitlistAvailable && (
        <div className="flex flex-row gap-2 p-2">
          <Button
            variant="ghost"
            onClick={() => setSelectedTab('members')}
            className={cn(selectedTab === 'members' && 'bg-primary-btn text-white pointer-events-none')}
          >
            Members ({membersTotal})
          </Button>
          <Button
            variant="ghost"
            onClick={() => setSelectedTab('waitlist')}
            className={cn(selectedTab === 'waitlist' && 'bg-primary-btn text-white pointer-events-none')}
          >
            Waitlist ({waitlistTotal})
          </Button>
        </div>
      )}
      {selectedTab === 'members' && (
        <RenderList
          key="members"
          total={membersTotal}
          title={eventLabels.participantLabel}
          loadMore={fetchMembers}
          hasMore={hasMoreMembers}
          data={members}
          isFetching={isFetchingMoreMembers}
          deleteMember={(id) => removeGuest(id, 'members')}
        />
      )}
      {selectedTab === 'waitlist' && (
        <RenderList
          key="waitlist"
          total={waitlistTotal}
          title={eventLabels.waitlist}
          loadMore={fetchWaitlist}
          hasMore={hasMoreWaitlist}
          data={waitlist}
          isFetching={isFetchingMoreWaitlist}
          deleteMember={(id) => removeGuest(id, 'waitlist')}
        />
      )}
    </Layout>
  );
};

export default Participants;

const useStyles = makeStyles({
  header: {
    fontFamily: 'Roboto',
    fontSize: 20,
    color: theme.TEXT_PRIMARY,
    fontWeight: 'bolder',
    padding: '10px 0',
    alignSelf: 'flex-start',
  },

  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
